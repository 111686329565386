import React from 'react';
import { Link, graphql } from 'gatsby';
import Seo from '../../components/seo.es';
import Layout from '../../components/layout.en';
import SolutionsBlockES from '../../components/solutionsblock.component.es';
import '../../styles/blog.scss';

const BlogPage = ({ data }) => {
	return (
		<Layout>
			<Seo title='Blog' />
			<div className='blog-home'>
				<h1>KuBlog</h1>
				<div className='blog-articles'>
					{data.allMdx.nodes.map((node) => (
						<article key={node.id}>
							<h2>
								<Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link>
							</h2>
							<p>Published on {node.frontmatter.date}</p>
						</article>
					))}
				</div>
			</div>
			<SolutionsBlockES />
		</Layout>
	);
};

export const query = graphql`
	query {
		allMdx(
			sort: { fields: frontmatter___date, order: DESC }
			filter: { frontmatter: { lang: { eq: "en" } } }
		) {
			nodes {
				frontmatter {
					date(formatString: "MMMM D, YYYY")
					title
				}
				id
				slug
			}
		}
	}
`;
export default BlogPage;
